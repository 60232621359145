import { useEffect } from "react";
import { IoMdCheckmarkCircle, IoMdCloseCircle, IoMdInformationCircle } from "react-icons/io";
import { useAlert } from "../../helpers/states/useAlert";

const Alert = () => {
	const { active, status, message, setAlert } = useAlert();

	useEffect(() => {
		let alertTimeout = setTimeout(() => {
			if (active) {
				setAlert({ active: false, status: "success", message: "" });
			}
		}, 6000);

		const alertElem = document.querySelector(".alert");
		alertElem.addEventListener("click", () => {
			setAlert({ active: false, status: "success", message: "" });
		});

		return () => {
			clearTimeout(alertTimeout);
		}
	}, [active, setAlert]);

	return (
		<div className={`alert ${active ? "active" : ""} ${status}`}>
			<div className="alert-wrapper">
				{status === "success" ? <IoMdCheckmarkCircle /> : null}
				{status === "danger" ? <IoMdCloseCircle /> : null}
				{status === "warning" ? <IoMdInformationCircle /> : null}
				<div className="alert-body">
					{message}
				</div>
			</div>
		</div>
	)
}

export default Alert;