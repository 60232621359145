import CallToAction from "../../../components/misc/CallToAction";
import { useSession } from "../../../helpers/states/useSession";
import { useAlert } from "../../../helpers/states/useAlert";
import usePageTitle from "../../../helpers/usePageTitle";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { logIn } from "../../../helpers/endpoints/users";

const Index = () => {
	const [formData, setFormData] = useState({ email_address: "", password: "" });
	const [isLoading, setIsLoading] = useState(false);
	const { setSession } = useSession();
	const { setAlert } = useAlert();
	const navigate = useNavigate();

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		setIsLoading(true);
		setSession({ hasSession: null });

		if (!formData.email_address || !formData.password) {
			setAlert({
				active: true,
				status: "danger",
				message: "All fields are required.",
			});

			setIsLoading(false);
		}

		const response = await axios.get(logIn, { params: { ...formData } });
		const { success, message, data, redirect } = response.data;

		setSession({ hasSession: response.data.succces, userData: data.owner_id, centers: data.centers });

		setAlert(
			{
				active: true,
				status: success ? "success" : "danger",
				message: message,
			}
		);

		setIsLoading(false);
		navigate(redirect);
	}

	usePageTitle("Log In");
	return (
		<section className="log-in">
			<div className="dash-wrapper">
				<div className="panel">
					<div className="panel-header">Welcome back!</div>
					<div className="panel-body">
						<form className="log-in-form" onSubmit={e => handleFormSubmit(e)}>
							<div className="input-control">
								<label>Email Address</label>
								<input type="text" onChange={e => setFormData({ ...formData, email_address: e.target.value })} />
							</div>

							<div className="input-control">
								<label>Password</label>
								<input type="password" onChange={e => setFormData({ ...formData, password: e.target.value })} />
							</div>

							<button
								className="btn-success elem-block"
								onSubmit={e => handleFormSubmit(e)}
								disabled={isLoading}
							>
								<h2 className="nogaps">{isLoading ? "Logging in..." : "Log In"}</h2>
							</button>
						</form>
					</div>
				</div>
			</div>

			<CallToAction />
		</section>
	)
}

export default Index;