import { shopeeProducts } from "../../assets/data/shopeeProducts";
import { useEffect, useState } from "react";
import "../../assets/css/sponsored.css";

const AMProducts = ({ services }) => {
	const [productlist, setProductList] = useState([]);

	useEffect(() => {
		const types = Object.keys(services).filter(service => services[service]);
		const getProducts = () => {
			const groupedProducts = shopeeProducts.reduce((acc, product) => {
				if (!acc[product.type]) {
					acc[product.type] = [];
				}
				acc[product.type].push(product);
				return acc;
			}, {});

			const result = types.map(type => {
				const productsOfType = groupedProducts[type];
				if (productsOfType) {
					const randomIndex = Math.floor(Math.random() * productsOfType.length);
					return productsOfType[randomIndex];
				}
				return null;
			}).filter(product => product !== null);
			return setProductList(result);
		}

		if (!productlist.length) getProducts();
	}, [productlist.length, services]);

	return (
		productlist.length ?
			<div className="sponsored">
				<div className="sponsored-wrapper">
					<div className="sponsored-header"><small>THERAPIST'S CHOICE</small></div>
					<div className="sponsored-body">
						<div className="sponsored-list custom-scrollbar">
							{
								productlist.map((product, index) =>
									<div key={index} className="sponsored-item">
										<a href={product.link} target="_new">
											<div
												className="sponsored-item-header"
												style={{ backgroundImage: `url(${product.image})` }}></div>

											<div className="sponsored-item-body">
												<p className="nogaps product-title ellipsis-2">{product.title}</p>
											</div>

											<div className="sponsored-item-footer">
												<button className="btn btn-sm btn-cta btn-rounded">Get One</button>
											</div>
										</a>
									</div>
								)
							}
						</div>
					</div>
				</div>
			</div>
			:
			null
	)
}

export default AMProducts;