import { useEffect, useState } from "react";
import { countersAPI } from "../../../helpers/endpoints/centers";
import axios from "axios";
import Loading from "../../../components/misc/Loading";

const CenterCounter = () => {
	const [counters, setCounters] = useState(null);

	useEffect(() => {
		const getAllCenters = async () => {
			const response = await axios.get(countersAPI);
			const { success, data } = response.data;

			if (success) {
				setCounters(data);
			}
		}

		if (counters === null) getAllCenters();
	}, [counters]);

	if (!counters) return;

	const regionsData = [{ code: "ncr", name: "Metro Manila" }, { code: "car", name: "Cordillera" }, { code: "i", name: "Ilocos" }, { code: "ii", name: "Cagayan" }, { code: "iii", name: "(C) Luzon" }, { code: "iv-a", name: "CaLaBaRzon" }, { code: "iv-b", name: "MiMaRoPa" }, { code: "v", name: "Bicol" }, { code: "vi", name: "(W) Visayas" }, { code: "vii", name: "(C) Visayas" }, { code: "viii", name: "(E) Visayas" }, { code: "ix", name: "Zamboanga" }, { code: "x", name: "(N) Mindanao" }, { code: "xi", name: "Davao" }, { code: "xii", name: "SocCSkSarGen" }, { code: "xiii", name: "CaRaGa" }, { code: "armm", name: "Muslim Mindanao" },];

	const serviceData = [{ name: "occupational_therapy", code: "OT" }, { name: "speech_therapy", code: "ST" }, { name: "applied_behavior_analysis", code: "ABA" }, { name: "special_education", code: "SpEd" }, { name: "speech_program", code: "SP" }, { name: "physical_therapy", code: "PT" }, { name: "playgroup", code: "PG" }, { name: "early_intervention", code: "EIP" },];

	return (
		<div className="center-counter page-section">
			<div className="container">
				<div className="align-center">
					<h2 style={{ fontSize: "2rem" }}>Therapy centers within your City</h2>
					<p>We work hard to collaborate with all the therapy centers nationwide to serve you better.</p>

					{counters === null && <Loading type={"small"} />}

					<div className="top-cities-wrapper">
						{
							counters &&
							counters.map(center =>
								<div key={center.region} className="top-city-item">
									<div className="top-city-header" style={{ backgroundImage: `url(${require("../../../assets/images/misc/regions/" + center.region.toLowerCase() + ".png")})` }}></div>
									<div className="top-city-body">
										<h4 style={{ marginBottom: 10 }}>
											{
												regionsData.map(region => region.code.toLowerCase() === center.region.toLowerCase() ? region.name : undefined)
											}
										</h4>
										<p>{center.count} Therapy Centers</p>
										<div className="centers-categories">
											{
												center.services.map(service =>
													<span key={service} className="capsule">
														{
															serviceData.map(svc => svc.name.toLowerCase() === service ? svc.code : undefined)
														}
													</span>
												)
											}
										</div>
									</div>
								</div>
							)
						}
					</div>

					<h4 style={{ fontSize: "1.4rem" }}>And many more!</h4>
				</div>
			</div>
		</div>
	)
}

export default CenterCounter;