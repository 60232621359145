import { useRecentSelectedCenter } from "../../helpers/states/useRecentSelectedCenter";
import { useUrlHistory } from "../../helpers/states/useUrlHistory";
import { getCentersById } from "../../helpers/endpoints/centers";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

const CentersHistory = () => {
	const { selectedCenters } = useRecentSelectedCenter();
	const [centers, setCenters] = useState(false);
	const { setUrlHistory } = useUrlHistory();

	useEffect(() => {
		const getCenters = async () => {
			const response = await axios.get(getCentersById, { params: { centerIds: [...selectedCenters] } });
			const { data } = response.data;

			setCenters(data);
		}

		if (selectedCenters.length && centers === false) {
			getCenters()
		}

	}, [centers, selectedCenters]);

	if (!centers) return;

	return (
		centers ?
			<div className="centers-history page-section">
				<div className="container">
					<h2>Search History</h2>

					<div className="centers-history-grid">
						{
							centers.map((center, index) =>
								<NavLink
									key={index}
									className="center-history-item"
									to={`/centers/${center._id.$oid}/${center.handle}?at=about`}
									onClick={e => setUrlHistory({ url: window.location.href })}>
									<div className="panel">
										<div className="panel-header ellipsis-1">{center.name}</div>
										<div className="panel-body">
											<p className="ellipsis-2">{center.complete_address}</p>
										</div>
									</div>
								</NavLink>
							)
						}
					</div>
				</div>
			</div>
			:
			undefined
	)
}

export default CentersHistory;