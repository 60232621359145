import { useAlert } from "../../helpers/states/useAlert";
import { FaLink, FaFacebook, FaXTwitter } from "react-icons/fa6";
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { Tooltip } from "react-tooltip";

const ShareCenterPanel = ({ centerData }) => {
	const { setAlert } = useAlert();

	const handleCopyLink = (link) => {
		if (link) {
			const textArea = document.createElement("textarea");

			textArea.value = link;
			document.body.appendChild(textArea);
			textArea.select();
			const isCopied = document.execCommand("copy");
			document.body.removeChild(textArea);

			if (isCopied) {
				setAlert({ active: true, status: "success", message: "Link copied to clipboard." });
			} else {
				setAlert({ active: true, status: "danger", message: "A problem has occurred while copying the link. Please refresh the page." });
			}
		} else {
			setAlert({ active: true, status: "danger", message: "A problem has occurred while copying the link. Please refresh the page." });
		}
	};

	return (
		<div className="panel border-radius-lg box-shadow">
			<div className="panel-header"><span style={{ color: "#474747" }}>Let's help one another, share {centerData.name} with a friend</span> ❤</div>
			<div className="panel-body">
				<div className="share-action-wrapper">
					<div className="share-action-item">
						<button
							data-tooltip-variant="info"
							data-tooltip-class-name="tooltip"
							data-tooltip-id="social-data"
							data-tooltip-html="Copy to clipboard"
							className="btn btn-theme-border"
							onClick={e => handleCopyLink(`https://ausometherapy.com/centers/${centerData._id.$oid}/${centerData.handle}`)}>
							<FaLink />
						</button>
					</div>
					<div className="share-action-item">
						<FacebookShareButton
							data-tooltip-variant="info"
							data-tooltip-class-name="tooltip"
							data-tooltip-id="social-data"
							data-tooltip-html="Share on Facebook"
							resetButtonStyle={false}
							className="btn btn-theme-border"
							url={`https://ausometherapy.com/centers/${centerData._id.$oid}/${centerData.handle}`}
							hashtag="#AusomeTherapy #OccupationalTherapy #SpeechTherapy #ABAtherapy #EarlyIntervention #Playgoup">
							<FaFacebook />
						</FacebookShareButton>
					</div>
					<div className="share-action-item">
						<TwitterShareButton
							data-tooltip-variant="info"
							data-tooltip-class-name="tooltip"
							data-tooltip-id="social-data"
							data-tooltip-html="Share on Twitter"
							resetButtonStyle={false}
							className="btn btn-theme-border"
							title={centerData.name}
							url={`https://ausometherapy.com/centers/${centerData._id.$oid}/${centerData.handle}`}
							hashtags={["AusomeTherapy", "OccupationalTherapy", "SpeechTherapy", "ABAtherapy", "EarlyIntervention", "Playgoup"]}>
							<FaXTwitter />
						</TwitterShareButton>
					</div>

					<Tooltip id="social-data" />
				</div>
			</div>
		</div>
	)
}

export default ShareCenterPanel;