import { BiMap, BiPhone, BiEnvelope } from "react-icons/bi";
import { MdCheckCircleOutline } from "react-icons/md";
import { NavLink } from "react-router-dom";

const About = ({ centerData }) => {
	return (
		<div className="about">
			<div className="page-content">
				<iframe src={centerData.google_map} className="iframe-map" allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map" itemProp="hasMap"></iframe>
			</div>

			<div className="panel border-radius-lg box-shadow">
				<div className="panel-header">Contact Details</div>
				<div className="panel-body">
					<p className="text-icon-flex"><BiMap /> {centerData.complete_address}</p>
					<p className="text-icon-flex"><BiPhone /> <NavLink to="tel:51247990">{centerData.primary_number}</NavLink></p>
					{
						centerData.secondary_number && <p className="text-icon-flex"><BiPhone /> <NavLink to={`tel:${centerData.secondary_number}`}>{centerData.secondary_number}</NavLink></p>
					}
					{
						centerData.email_address && <p className="text-icon-flex"><BiEnvelope /> <NavLink style={{ wordBreak: "break-all" }} to={`mailto:${centerData.email_address}`}>{centerData.email_address}</NavLink></p>
					}
				</div>
			</div>

			<div className="panel border-radius-lg box-shadow">
				<div className="panel-header">Services</div>
				<div className="panel-body">
					{
						centerData.services &&
						Object.keys(centerData.services).map((service, key) => centerData.services[service] ? <p key={key} className="text-icon-flex text-capitalize"><MdCheckCircleOutline /> {service.replaceAll("_", " ")}</p> : null)
					}
				</div>
			</div>
		</div >
	)
}

export default About;