import { searchCenterNameAPI, searchCenterLocationAPI } from "../../../helpers/endpoints/search";
import { useSearchResult } from "../../../helpers/states/useSearchResult";
import { filterData } from "../../../assets/data/filterData";
import { useAlert } from "../../../helpers/states/useAlert";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { useState } from "react";
import axios from "axios";

const Hero = () => {
	const navigate = useNavigate();
	const { setAlert } = useAlert();
	const { setSearchResultData } = useSearchResult();
	const [formData, setFormData] = useState({ type: "location", keywords: "", filters: [] });
	const [searchFilters, setSearchFilters] = useState(
		[
			"occupational_therapy",
			"speech_therapy",
			"playgroup",
			"special_education",
			"early_intervention",
			"applied_behavior_analysis",
			"physical_therapy"
		]
	);

	const selectFilter = (value) => {
		if (searchFilters.includes(value)) {
			setSearchFilters(prev => prev.filter(e => e !== value));
		} else {
			setSearchFilters([value, ...searchFilters]);
		}
	}

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		if (!formData.keywords) return setAlert({ active: true, status: "warning", message: `Please enter the ${formData.type === "location" ? "location" : "center name"} to search.` });

		if (formData.keywords.length < 3) return setAlert({ active: true, status: "warning", message: `${formData.type === "location" ? "Location" : "Center name"} keyword should at least 3 characters long.` });

		// resets result data
		setSearchResultData({ searchType: formData.type, searchResultData: null, searchFilters: [], searchKeywords: "" });

		let response = "";

		if (formData.type === "location") {
			response = await axios.get(searchCenterLocationAPI, { params: { ...formData, filters: [...searchFilters] } });
		}

		if (formData.type === "center") {
			response = await axios.get(searchCenterNameAPI, { params: { keywords: formData.keywords } });
		}

		const { data, keywords, filters, redirect } = response.data;

		setSearchResultData({
			searchKeywords: keywords,
			searchFilters: filters,
			searchResultData: data,
			searchType: formData.type,
		});

		navigate(redirect);
	}

	const handleSearchType = (e) => {
		setFormData({ ...formData, type: e.target.id })
		setSearchFilters(
			[
				"occupational_therapy",
				"speech_therapy",
				"special_education",
				"playgroup",
				"early_intervention",
				"applied_behavior_analysis",
				"physical_therapy",
			]
		)
	}

	return (
		<section className="hero">
			<div className="hero-label-parent">

				{
					formData.type === "location" &&
					<div className="hero-label-filter">
						<h2 className="hero-label-content">FIND</h2>

						<div className="filter-category-parent">
							{
								filterData.map((filter, index) =>
									<div
										key={index}
										className="filter-category-item"
										data-tooltip-variant="info"
										data-tooltip-class-name="tooltip"
										data-tooltip-id="tooltip"
										data-tooltip-html={filter.longName}>
										<p>{filter.shortName}</p>
										<label className="switch">
											<input
												type="checkbox"
												value={filter.value}
												checked={searchFilters.includes(filter.value)}
												onChange={e => selectFilter(e.target.value)} />
											<span className="slider round"></span>
										</label>
									</div>)
							}
						</div>
					</div>
				}

				<form className="search-form" onSubmit={e => handleFormSubmit(e)}>
					<div className="search-form-wrapper">
						<div className={`search-form-item keyword-item ${formData.type === "center" && "wider"}`}>
							<div className="search-type">
								<p
									id="location"
									data-tooltip-variant="info"
									data-tooltip-class-name="tooltip"
									data-tooltip-id="tooltip"
									data-tooltip-html="Search by location."
									className={formData.type === "location" ? "active" : undefined}
									onClick={e => handleSearchType(e)}>Location</p>

								<p
									id="center"
									data-tooltip-variant="info"
									data-tooltip-class-name="tooltip"
									data-tooltip-id="tooltip"
									data-tooltip-html="Search by name."
									className={formData.type === "center" ? "active" : undefined}
									onClick={e => handleSearchType(e)}>Name</p>
							</div>
							<input
								type="text"
								name="keywords"
								placeholder={formData.type === "location" ? "Antipolo City" : "Center name"}
								value={formData?.keywords}
								onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })} />
						</div>

						<div className="search-form-item filter-item">
							<p>Filter</p>
							<input
								type="text"
								disabled={true}
								data-tooltip-variant="info"
								data-tooltip-class-name="tooltip"
								data-tooltip-id="tooltip"
								data-tooltip-html={
									searchFilters.length === 7 ?
										searchFilters.join(", ").replaceAll("_", " ")
										:
										searchFilters.length === 0 ? searchFilters.join(", ").replaceAll("_", " ") : searchFilters.join(", ").replaceAll("_", " ")}
								value={
									searchFilters.length === 7 ?
										"All Services"
										:
										searchFilters.length === 0 ? "All Services" : searchFilters.join(", ").replaceAll("_", " ")} />
						</div>
						<Tooltip id="tooltip" />

						<div className="search-form-item submit-item">
							<button onSubmit={e => handleFormSubmit(e)}>
								<span className="visible-sm" style={{ marginRight: 10 }}>Search</span> <FaSearch />
							</button>
						</div>

					</div>
				</form>
			</div>
		</section>
	)
}

export default Hero;