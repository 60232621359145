import { GoHeartFill } from "react-icons/go";
import { MdFacebook } from "react-icons/md";
import { FaStar } from "react-icons/fa6";

const Info = () => {
	return (
		<section className="info page-section">
			<div className="container">

				<div className="info-header">
					<h2 style={{ fontSize: "2rem" }}>Join the Ausome Therapy Community!</h2>
					<p>We believe that a strong community of like-minded people would help each other cross even the toughest obstacle. Let's share experiences, challenges, and small wins, one day at a time!</p>
				</div>

				<div className="info-grid community">
					<div className="info-image"></div>
					<div className="info-context right">
						<h3>A PASSIONATE COMMUNITY <GoHeartFill className="color-theme" /></h3>

						<p>Ausome Therapy, your go-to solution for finding Occupational Therapy (OT), Speech Therapy (ST), and Developmental Pediatrics (Dev Ped) centers in the Philippines, is supported by loving parents, and guardians.</p>

						<p>Together, let's share experiences, learn from other parents, know the latest updates about pediatric therapy, and gain valuable insights into enhancing the lives of our loving ones.</p>

						<p>Join the Ausome Therapy community on Facebook today!</p>
						<a href="https://www.facebook.com/groups/ausometherapy" target="_new" className="btn btn-theme">JOIN THE COMMUNITY TODAY <MdFacebook className="icon-right" /></a>
					</div>
				</div>

				<div className="info-grid community-2">
					<div className="info-context left">
						<h3>GET EXCLUSIVE UPDATES <FaStar className="color-theme" /></h3>

						<p>Join our lively Facebook group at Ausome Therapy for special updates, helpful info on therapy centers, useful reviews, and a supportive community of caregivers and therapists.</p>

						<p>It's a supportive community where you can find useful information and make friends. Don't miss out on the fun – join us today and be part of something special!</p>

						<p>We are excited to meet you there!</p>
						<a href="https://www.facebook.com/groups/ausometherapy" target="_new" className="btn btn-theme">JOIN THE COMMUNITY TODAY <MdFacebook className="icon-right" /></a>
					</div>
					<div className="info-image"></div>
				</div>
			</div>
		</section >
	)
}

export default Info;