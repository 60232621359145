export const filterData = [
	{
		shortName: "OT",
		longName: "Occupational Therapy",
		value: "occupational_therapy",
	},
	{
		shortName: "ST",
		longName: "Speech Therapy",
		value: "speech_therapy",
	},
	{
		shortName: "PG",
		longName: "Playgroup",
		value: "playgroup",
	},
	{
		shortName: "SpEd",
		longName: "Special Education",
		value: "special_education",
	},
	{
		shortName: "EIP",
		longName: "Early Intervention Program",
		value: "early_intervention",
	},
	{
		shortName: "ABA",
		longName: "Applied Behavior Analysis",
		value: "applied_behavior_analysis",
	},
	{
		shortName: "PT",
		longName: "Physical Therapy",
		value: "physical_therapy",
	},
]