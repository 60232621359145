import { useBlogger } from "../../../helpers/states/useBlogger";
import { allBlogsAPI } from "../../../helpers/endpoints/blogs";
// import Sponsored from "../../../components/misc/Sponsored";
import Loading from "../../../components/misc/Loading";
import { useEffect } from "react";
import axios from "axios";

const Posts = () => {
	const { blogList, setBlogList } = useBlogger();

	useEffect(() => {
		const getAllBlogs = async () => {
			const response = await axios.get(allBlogsAPI);

			const { data } = response.data;

			setBlogList({ blogList: data });
		}

		if (!blogList) {
			getAllBlogs();
		}
	}, [blogList, setBlogList]);

	const getExcerptContentv = (html, id = "excerpt") => {
		const parser = new DOMParser();
		const doc = parser.parseFromString(html, "text/html");
		const element = doc.getElementById(id);

		if (!element) return { text: null, src: null };

		const text = element.textContent;
		const src = element.tagName === 'IMG' ? element.getAttribute('src') : null;

		return { text, src };
	}

	if (blogList === null) return <Loading type="small" />;

	const convertDate = (published) => {
		const date = new Date(published);

		const options = { year: "numeric", month: "long", day: "2-digit", hour: "2-digit", minute: "2-digit", weekday: "short" };
		return date.toLocaleDateString("en-US", options);
	}

	return (
		<>
			{/* <Sponsored /> */}

			<div className="text-strikethrough"><hr></hr><span>NEWS FEEDS</span></div>
			<div className="posts">

				{!blogList.length && <p style={{ textAlign: "center", padding: "60px 0" }}>No News Feeds to display.</p>}

				{
					blogList.map(blog =>
						<div key={blog.postId} id={blog.postId} className="blog-item panel border-radius-lg">
							<div className="blog-body panel-body">
								<a href={blog.url} target="_new">
									<img src={getExcerptContentv(blog.content, "image").src} alt={blog.title} className="blog-content-image" />
								</a>
								<div className="blog-content-body">

									<div className="blog-author-date-block">
										<div className="blog-author-block">
											<img src={blog.authorImage} alt={blog.authorName} className="blog-author-image" />
											<div className="blog-author-details">
												<small className="blog-author-name">{blog.authorName}</small>
											</div>
										</div>
										<small style={{ display: "block", color: "#bbb" }}>{convertDate(blog.published)}</small>
									</div>

									<div className="blog-content-separator"></div>

									<h3 className="blog-content-title">
										<a href={blog.url} target="_new">{blog.title}</a>
									</h3>

									<p className="blog-content-excerpt">{getExcerptContentv(blog.content).text}.. <a className="blog-read-more" href={blog.url}>Read more</a></p>
								</div>
							</div>

							<div className="blog-footer panel-footer">
								{
									blog.comments.length ?
										<>
											<small style={{ marginBottom: 5, display: "block" }}>Comments:</small>
											<div className="blog-comment-item">
												<div className="blog-comment-header">
													<img src={blog.comments[blog.comments.length - 1].author.image.url} alt="" className="blog-comment-image" />
													<small className="blog-comment-display-name">{blog.comments[blog.comments.length - 1].author.displayName} says: </small>
													<small className="ellipsis-1"><b>{blog.comments[blog.comments.length - 1].content}</b></small>
												</div>
											</div>
											<a href={blog.url}><button className="btn-sm btn-primary btn-rounded">Share yours</button></a>
										</>
										:
										<a href={blog.url}><button className="btn-sm btn-primary btn-rounded">Share a comment</button></a>
								}
							</div>

						</div>
					)
				}
			</div>
		</>
	)
}

export default Posts;