import { useSearchResult } from "../../../helpers/states/useSearchResult";
import { useAlert } from "../../../helpers/states/useAlert";
import { useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { useState } from "react";
import axios from "axios";
import { searchCenterLocationAPI, searchCenterNameAPI } from "../../../helpers/endpoints/search";

const SearchV2 = ({ searchType, searchKeywords }) => {
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		type: searchType,
		keywords: searchKeywords || "",
		filters:
			[
				"occupational_therapy",
				"speech_therapy",
				"special_education",
				"playgroup",
				"early_intervention",
				"speech_program",
				"applied_behavior_analysis",
				"physical_therapy",
			]
	});
	const { setSearchResultData } = useSearchResult();
	const { setAlert } = useAlert();

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		if (!formData.keywords) return setAlert({ active: true, status: "warning", message: "Please enter location to search." });

		if (formData.keywords.length < 3) return setAlert({ active: true, status: "warning", message: "Location keywords should at least 3 characters long." });

		setSearchResultData({ seachType: formData.type, searchKeywords: formData.keywords, searchResultData: null });

		let response = "";

		if (formData.type === "location") {
			response = await axios.get(searchCenterLocationAPI, { params: { ...formData } });
		}

		if (formData.type === "center") {
			response = await axios.get(searchCenterNameAPI, { params: { keywords: formData.keywords } });
		}

		const { data, keywords, redirect } = response.data;

		setSearchResultData({
			searchKeywords: keywords,
			searchFilters: formData.filters,
			searchResultData: data,
			searchType: formData.type,
		});

		navigate(redirect);
	}

	const handleSearchType = (e) => {
		setFormData({
			...formData,
			type: e.target.value,
			keywords: searchType !== e.target.value ? "" : searchKeywords,
		});

		document.querySelector('input[name="keywords"]').focus();
	}

	return (
		<>
			<form className="search-form-v2" onSubmit={e => handleFormSubmit(e)}>
				<div className="input-control input-control-flex">
					<select
						name="type"
						defaultValue={searchType}
						onChange={e => handleSearchType(e)}>
						<option value="location">Location</option>
						<option value="center">Name</option>
					</select>

					<input
						type="text"
						name="keywords"
						value={formData.keywords}
						onChange={e => setFormData({ ...formData, [e.target.name]: e.target.value })} />

					<button className="btn-theme" onSubmit={e => handleFormSubmit(e)}><FaSearch className="visible-xs" /> <span className="hidden-xs">Search</span></button>
				</div>
			</form>
		</>
	)
}

export default SearchV2;