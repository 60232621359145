import CallToAction from "../../components/misc/CallToAction";
import CenterCounter from "./components/CenterCounter";
import Featured from "./components/Featured";
import { Helmet } from "react-helmet-async";
import Testi from "./components/Testi";
import Hero from "./components/Hero";
import Info from "./components/Info";
import "./landing.css";

const Index = () => {
	return (
		<div className="landing">
			<Helmet>
				<title>Find child developmental therapy centers near you | Ausome Therapy</title>
				<meta name="description" content="Ausome Therapy helps you find therapy centers that caters occupational therapy, speech therapy, Applied Behavior Analysis (ABA) therapy, Early Intervention, Playgroup programs and more near you." />
				<meta itemprop="name" content="Ausome Therapy" />
				<meta itemprop="description" content="Ausome Therapy helps you find therapy centers that caters occupational therapy, speech therapy, Applied Behavior Analysis (ABA) therapy, Early Intervention, Playgroup programs and more near you." />
				<meta name="keywords" content="child development therapy center, occupational therapy, speech therapy, applied behavior analysis (aba) therapy, early intervention, playgroup programs" />
				<meta itemprop="image" content={require("../../assets/images/misc/hero-bg-blur.jpg")} />

				<meta property="og:title" content="Find child developmental therapy centers near you | Ausome Therapy" />
				<meta property="og:description" content="Ausome Therapy helps you find therapy centers that caters occupational therapy, speech therapy, Applied Behavior Analysis (ABA) therapy, Early Intervention, Playgroup programs and more near you." />
				<meta property="og:image" content={require("../../assets/images/misc/hero-bg-blur.jpg")} />
				<meta property="og:url" content="https://ausometherapy.com" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Ausome Therapy" />
			</Helmet>

			<Hero />

			<Featured />

			<CenterCounter />

			<Info />

			<Testi />

			<CallToAction />
		</div>
	)
}

export default Index;