import selectList from "../../../../assets/images/misc/select-item.jpg";
import nokeywords from "../../../../assets/images/misc/nokeywords.jpg";
import CenterDataPreview from "./components/CenterDataPreview";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CenterItem from "./components/CenterItem";
import { FaArrowUp } from "react-icons/fa6";
import "./center-list.css";

const CenterList = ({ searchKeywords, searchResultData, searchType }) => {
	const [centerData, setCenterData] = useState(null);
	const [searchParams] = useSearchParams();
	const urlQuery = searchParams.get("query");
	const navContainer = useRef(null);

	useEffect(() => {
		setCenterData(null);
	}, [urlQuery]);

	const handleSelectCenter = ({ target, data }) => {
		const parents = document.querySelectorAll(".center-item");
		const previewElem = document.querySelector(".center-list-preview");
		const resultPageSearch = document.querySelector(".result-page-search");
		const centerListNavWrapper = document.querySelector(".center-list-nav-wrapper");

		previewElem.classList.remove("active");
		resultPageSearch.classList.remove("active");
		centerListNavWrapper.classList.remove("active");

		parents.forEach((e, i) => {
			e.classList.remove("active");
		})

		while (target !== null && !target.classList.contains("center-item")) {
			target = target.parentElement;
		}

		if (target !== null) {
			target.classList.add("active");
			previewElem.classList.add("active");
			resultPageSearch.classList.add("active");
			centerListNavWrapper.classList.add("active");
			setCenterData(data);
		}
	}

	const scrollToTop = () => {
		if (navContainer.current) {
			navContainer.current.scrollTop = 0;
		}
	}

	return (
		<div className="center-list-parent">
			<div className="center-list-grid">
				{centerData && <div className="data-preview-overlay"></div>}
				<nav className="center-list-nav-wrapper custom-scrollbar" ref={navContainer}>
					{
						searchResultData &&
						searchResultData.map((center, index) => <CenterItem key={center._id.$oid} center={center} selectCenter={handleSelectCenter} />)
					}

					{
						searchResultData.length > 20 && <div style={{ textAlign: "center" }}><button className="hidden-xs" style={{ borderRadius: 100, padding: "6px 13px" }} onClick={scrollToTop}><FaArrowUp /></button></div>
					}
				</nav>

				<main className="center-list-preview">
					{
						centerData &&
						<div className="center-list-preview-wrapper">
							<CenterDataPreview center={centerData} setCenterData={setCenterData} />
						</div>
					}
				</main>

				{
					!searchResultData.length ?
						<div className="no-search-found">
							<img src={nokeywords} alt="" />
							<div className="no-search-found-content">
								<p>We're working hard to on board all the therapy centers in Ausome Therapy Community to serve you better.</p>
								{
									searchType === "location" ?
										<p>At this time, we <b>did not find</b> any centers or schools within <b><span className="text-capitalize">{searchKeywords}</span></b> area.</p>
										:
										<p>At this time, we <b>did not find</b> any centers or schools matches the <b><span className="text-capitalize">{searchKeywords}</span></b> keyword.</p>
								}
							</div>
						</div>
						:
						null
				}

				{
					searchResultData.length && centerData === null ?
						<div className="search-found-dialog">
							<img src={selectList} alt="" />
							<div className="search-found-dialog-content">
								<p>Ausome Therapy found <b>{searchResultData.length} {searchResultData.length > 1 ? "therapy centers" : "center"} </b> {searchType === "location" ? `within ${searchKeywords} area.` : `matches the ${searchKeywords} keyword.`}</p>
								<p>Please select one from the result list.</p>
							</div>
						</div>
						:
						null
				}
			</div>
		</div>
	)
}

export default CenterList;