
const CenterItem = ({ center, selectCenter }) => {
	return (
		<div className="center-item" onClick={e => selectCenter({ target: e.target, data: center })}>
			<div className="center-dialog">
				<div className="center-details">
					<h3 className="center-item-title ellipsis-1">{center.name}</h3>
					<p className="center-item-subtitle ellipsis-2">{`${center.street} ${center.city} (${center.region}  -  ${center.province})`}</p>
				</div>
			</div>
		</div>
	)
}

export default CenterItem;