import { useSession } from "./helpers/states/useSession";
import SingleCenter from "./pages/single-center/Index";
import EIP from "./pages/services/EarlyIntervention";
import { useAlert } from "./helpers/states/useAlert";
import { HelmetProvider } from "react-helmet-async";
import Privacy from "./pages/posts/legal/Privacy";
import LogIn from "./pages/account/log-in/Index";
import { Routes, Route } from "react-router-dom";
import OT from "./pages/services/Occupational";
import Terms from "./pages/posts/legal/Terms";
import PG from "./pages/services/Playgroup";
import Alert from "./components/misc/Alert";
import Landing from "./pages/landing/Index";
import PT from "./pages/services/Physical";
import Search from "./pages/search/Index";
import ErrorPage from "./pages/404/Index";
import ST from "./pages/services/Speech";
import Sped from "./pages/services/Sped";
import ABA from "./pages/services/Aba";
import Main from "./layouts/Main";
import Post from "./layouts/Post";

const App = () => {
    const { hasSession } = useSession();
    const { active } = useAlert();

    return (
        <HelmetProvider>
            <Routes>
                <Route path="/" element={<Main />}>
                    {!hasSession && <Route path="account/log-in" element={<LogIn />} />}

                    <Route path="/" element={<Landing />} />
                    <Route path="/search" element={<Search />} />

                    <Route path="/centers/:center_id/:handle" element={<SingleCenter />} />

                    <Route path="/occupational-therapy/:city" element={<OT />} />
                    <Route path="/occupational-therapy-centers/:city" element={<OT />} />

                    <Route path="/speech-therapy/:city" element={<ST />} />
                    <Route path="/speech-therapy-centers/:city" element={<ST />} />

                    <Route path="/aba-therapy/:city" element={<ABA />} />
                    <Route path="/aba-therapy-centers/:city" element={<ABA />} />
                    <Route path="/applied-behavior-analysis/:city" element={<ABA />} />
                    <Route path="/applied-behavior-analysis-centers/:city" element={<ABA />} />
                    <Route path="/applied-behavior-analysis-therapy-centers/:city" element={<ABA />} />

                    <Route path="/special-education/:city" element={<Sped />} />
                    <Route path="/special-education-centers/:city" element={<Sped />} />
                    <Route path="/special-education-schools/:city" element={<Sped />} />
                    <Route path="/sped/:city" element={<Sped />} />
                    <Route path="/sped-centers/:city" element={<Sped />} />
                    <Route path="/sped-schools/:city" element={<Sped />} />

                    <Route path="/physical-therapy/:city" element={<PT />} />
                    <Route path="/physical-therapy-centers/:city" element={<PT />} />

                    <Route path="/playgroup/:city" element={<PG />} />
                    <Route path="/playgroup-centers/:city" element={<PG />} />
                    <Route path="/playgroup-schools/:city" element={<PG />} />

                    <Route path="/early-intervention/:city" element={<EIP />} />
                    <Route path="/early-intervention-program/:city" element={<EIP />} />

                    <Route path="*" element={<ErrorPage />} />
                </Route>

                <Route path="/posts" element={<Post />}>
                    <Route path="legal/privacy" element={<Privacy />} />
                    <Route path="legal/terms" element={<Terms />} />
                </Route>
            </Routes>

            {active ? <Alert /> : null}
        </HelmetProvider>
    )
}

export default App;