import ShareCenterPanel from "../../components/misc/ShareCenterPanel";
import { useUrlHistory } from "../../helpers/states/useUrlHistory";
import { useCenterData } from "../../helpers/states/useCenterData";
import { getSingleCenter } from "../../helpers/endpoints/centers";
import CallToAction from "../../components/misc/CallToAction";
import bg from "../../assets/images/misc/hero-bg-blur.jpg";
import AMProducts from "../../components/misc/AMProducts";
import { NavLink, useParams } from "react-router-dom";
import Loading from "../../components/misc/Loading";
import { FaArrowLeft } from "react-icons/fa";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Posts from "./components/Posts";
import About from "./components/About";
import PageError from "../404/Index";
import "./single-center.css";
import axios from "axios";

const Index = () => {
	const { setCenterData, centerData } = useCenterData();
	const { url, setUrlHistory } = useUrlHistory();
	const [floatBar, setFloatBar] = useState(false);
	const { center_id, handle } = useParams();

	useEffect(() => {
		setCenterData({ centerData: null });
	}, [setCenterData]);

	useEffect(() => {
		const getCenterData = async () => {
			const response = await axios.get(getSingleCenter, { params: { center_id } });
			const { data } = response.data;
			setCenterData({ centerData: data });
		}

		if (centerData === null) getCenterData();
	}, [centerData, center_id, setCenterData]);

	useEffect(() => {
		const handleScroll = () => {
			const offset = window.scrollY;
			if (offset > 215) {
				setFloatBar(true);
			}

			if (offset < 215) {
				setFloatBar(false);
			}
		};

		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	if (centerData === null) return <Loading />;
	if (centerData === false) return <PageError />;

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
	};

	return (
		<div className="single-center">
			<Helmet>
				<title>{centerData.name} | Ausome Therapy</title>

				<meta name="description" content={`${centerData.name} may cater Occupational Therapy, Speech Therapy, ABA Therapy, Playgroup Program, Special Education, and Early Intervention.`} />

				<meta itemprop="name" content={`${centerData.name} | Ausome Therapy`} />
				<meta itemprop="description" content={`${centerData.name} may cater Occupational Therapy, Speech Therapy, ABA Therapy, Playgroup Program, Special Education, and Early Intervention.`} />
				<meta name="keywords" content={`${centerData.name}, child development therapy center, occupational therapy, speech therapy, applied behavior analysis (aba) therapy, early intervention, playgroup programs`} />
				<meta itemprop="image" content="https://ausometherapy.com/ausometherapy.png" />

				<meta property="og:title" content={`${centerData.name} | Ausome Therapy`} />
				<meta property="og:description" content={`${centerData.name} may cater Occupational Therapy, Speech Therapy, ABA Therapy, Playgroup Program, Special Education, and Early Intervention.`} />
				<meta property="og:image" content="https://ausometherapy.com/ausometherapy.png" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content={`${centerData.name} | Ausome Therapy`} />
			</Helmet>

			<div className="center-page-wrapper">
				<div className="page-header">
					<img src={bg} alt="" className="page-cover" />
					<div className="page-name-wrapper">
						<div className="page-name">
							<h1>{centerData.name}</h1>
							<p className="page-handle">
								<NavLink to={`/centers/${center_id}/${handle}`}>@{centerData.handle}</NavLink>
							</p>
						</div>
					</div>
				</div>

				<div className="page-body">
					<div className={`page-control-parent ${floatBar && "float-me"}`}>
						{
							floatBar &&
							<div className="page-control-parent-basic">
								<div className="page-control-inner" onClick={scrollToTop}>
									<h2>{centerData.name}</h2>
									<p className="page-handle nogaps">
										<NavLink to={`/centers/${center_id}/${handle}`}>@{centerData.handle}</NavLink>
									</p>
								</div>
								{
									url &&
									<NavLink
										to={url}
										onClick={e => setUrlHistory({ url: false })}
										className="btn nav-back-btn visible-sm">
										<FaArrowLeft style={{ color: "var(--color-theme)" }} />
									</NavLink>
								}
							</div>
						}
					</div>

					<div className="page-content-wrapper">

						<About centerData={centerData} />

						<div style={{ marginBottom: 20 }}></div>

						<ShareCenterPanel centerData={centerData} />

						<AMProducts services={centerData.services} />

						<Posts />
					</div>
				</div>
			</div>

			<div className="text-strikethrough"><hr></hr><span>End of Page</span></div>

			<CallToAction />
		</div>
	)
}

export default Index;