import { useRecentSelectedCenter } from "../../../../../helpers/states/useRecentSelectedCenter";
import { useUrlHistory } from "../../../../../helpers/states/useUrlHistory";
import { TbChevronCompactDown } from "react-icons/tb";
import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const CenterDataPreview = ({ center, setCenterData }) => {
	const { setUrlHistory } = useUrlHistory();
	const { setSelectedCenter } = useRecentSelectedCenter();
	const targetElementRef = useRef(null);
	const [startY, setStartY] = useState(0);
	const [currentY, setCurrentY] = useState(0);
	const [windowHeight, setWindowHeight] = useState(0);

	useEffect(() => {
		setUrlHistory({ url: false });
	}, [setUrlHistory]);

	useEffect(() => {
		const targetElement = targetElementRef.current;
		if (targetElement) document.body.classList.add("no-scroll");

		return () => document.body.classList.remove("no-scroll");
	}, [center]);

	const handleClickEvent = (center_id) => {
		const body = document.body;
		body.classList.remove("no-scroll");
		setUrlHistory({ url: window.location.href });
		setSelectedCenter(center_id);
	}

	const handleClosePreview = () => {
		const parents = document.querySelectorAll(".center-item");
		const previewElem = document.querySelector(".center-list-preview");
		const resultPageSearch = document.querySelector(".result-page-search");
		const centerListNavWrapper = document.querySelector(".center-list-nav-wrapper");

		resultPageSearch.classList.remove("active");
		centerListNavWrapper.classList.remove("active");

		parents.forEach(e => {
			e.classList.remove("active");
		})

		setTimeout(() => {
			setCenterData(null);
			previewElem.classList.remove("active");
			previewElem.style.transform = "unset";
		}, 80);
	}

	const handleTouchStart = (e) => {
		setStartY(e.touches[0].clientY);
		setWindowHeight(window.innerHeight);
	};

	const handleTouchMove = (e) => {
		setCurrentY(e.touches[0].clientY);

		const deltaY = currentY - startY;
		const popup = document.querySelector('.center-list-preview.active');

		if (currentY > startY && popup) {
			popup.style.transform = `translateY(${deltaY}px)`;
			popup.style.transition = "unset";
		}
	};

	const handleTouchEnd = (e) => {
		const windowLimit = windowHeight - (windowHeight / 2);
		const popup = document.querySelector('.center-list-preview.active');

		if (currentY > windowLimit && popup) {
			popup.style.transform = `translateY(1000px)`;
			popup.style.transition = "all .5s ease";

			handleClosePreview();

			setTimeout(() => {
				document.body.classList.remove("no-scroll");
			}, 80);
		} else {
			popup.style.transform = `translateY(0)`;
			popup.style.transition = "all .5s ease";
		}
	}

	return (
		<div className="popup-container">
			<div
				className="center-list-preview-close-bar"
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}>
				<TbChevronCompactDown />
			</div>

			<article className="center-data-preview custom-scrollbar" ref={targetElementRef} itemScope itemType="https://schema.org/ChildCare">
				<div className="map-parent">
					<iframe src={center.google_map} className="iframe-map" allowFullScreen={false} loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="map" itemProp="hasMap"></iframe>
				</div>

				<div className="center-data-basic-details">
					<h2 itemProp="name" className="color-theme">
						<NavLink
							to={`/centers/${center._id.$oid}/${center.handle}`}
							onClick={e => handleClickEvent(center._id.$oid)}>
							{center.name}
						</NavLink>
					</h2>
					<div itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
						<span itemProp="streetAddress">{center.street}</span> <span itemProp="addressLocality">{center.city}</span> <span>({center.region}</span> - <span>{center.province})</span>
					</div>
				</div>

				<div className="center-services" itemScope itemType="https://schema.org/Service">
					<meta itemProp="serviceType" content="Therapy Services" />
					<label className="section-label">Services</label>
					<div className="center-preview-services" itemProp="hasOfferCatalog" itemScope itemType="https://schema.org/OfferCatalog">
						{
							center?.services &&
							Object.keys(center.services).map((service, index) => center.services[service] ?
								<p
									key={service}
									itemProp="itemOffered"
									itemScope itemType="https://schema.org/Service"
									className="text-capitalize">
									<span className="visible-sm">• </span> <span itemProp="name">{service.replaceAll("_", " ")}</span>
								</p>
								:
								null
							)
						}
					</div>
				</div>

				<div className="center-data-preview-footer">
					<h4>Ready to schedule a visit?</h4>
					<NavLink
						to={`/centers/${center._id.$oid}/${center.handle}`}
						onClick={e => handleClickEvent(center._id.$oid)}
						className="btn btn-theme" itemProp="mainEntityOfPage">Contact Us Now <FaArrowRight style={{ marginLeft: 8 }} />
					</NavLink>
				</div>
			</article>
		</div>
	)
}

export default CenterDataPreview;