import { useBlogger } from "../../helpers/states/useBlogger";
import { allBlogsAPI } from "../../helpers/endpoints/blogs";
import { GoHeartFill, GoBug } from "react-icons/go";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import "./footer.css";

const Footer = () => {
	const { blogList, setBlogList } = useBlogger();

	useEffect(() => {
		const getAllBlogs = async () => {
			const response = await axios.get(allBlogsAPI);

			const { data } = response.data;

			setBlogList({ blogList: data });
		}

		if (!blogList) {
			getAllBlogs();
		}
	}, [blogList, setBlogList]);

	return (
		<section className="footer">
			<div className="container">
				<div className="footer-grid">
					<div>
						<h3>Ausome Therapy</h3>
						<ul>
							<li><NavLink to={"/"}>Home</NavLink></li>
							<li><NavLink to={"/posts/legal/privacy"}>Privacy Policy</NavLink></li>
							<li><NavLink to={"/posts/legal/terms"}>Terms and Use</NavLink></li>
						</ul>
					</div>

					<div>
						<h3>Let's Connect</h3>
						<ul>
							<li><NavLink to={"https://www.facebook.com/groups/ausometherapy"} target="_new">Facebook <GoHeartFill className="color-theme" /></NavLink></li>
							<li><NavLink to="mailto:ausometherapy@gmail.com">Email us</NavLink></li>
							<li><NavLink to="mailto:ausometherapy@gmail.com">Found a bug? <GoBug /></NavLink></li>
						</ul>
					</div>

					<div>
						<h3>Blogs</h3>

						{
							blogList === null ?
								<p>Stay tuned!</p>
								:
								<ul>
									{
										blogList.map((blog, index) => index < 3 && <li key={index} style={{ marginBottom: 10 }}>
											<a href={blog.url} target="_new" className="ellipsis-1">{blog.title}</a>
										</li>)
									}
								</ul>
						}
					</div>
				</div>
			</div>
		</section>
	)
}

export default Footer;