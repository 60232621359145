import { Helmet } from "react-helmet-async";
import PostHero from "../components/PostHero";

const Terms = () => {
	return (
		<>
			<Helmet>
				<title>Terms and Use | Ausome Therapy</title>
				<meta name="description" content={`Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the Ausome Therapy website (the "Service") operated by Ausome Therapy ("us", "we", or "our").`} />

				<meta itemprop="name" content="Ausome Therapy" />
				<meta itemprop="description" content={`Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the Ausome Therapy website (the "Service") operated by Ausome Therapy ("us", "we", or "our").`} />
				<meta name="keywords" content="Ausome Therapy, Terms and Use" />
				<meta itemprop="image" content={require("../../../assets/images/misc/ausometherapy.png")} />

				<meta property="og:title" content="Terms and Use | Ausome Therapy" />
				<meta property="og:description" content={`Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the Ausome Therapy website (the "Service") operated by Ausome Therapy ("us", "we", or "our").`} />
				<meta property="og:image" content={require("../../../assets/images/misc/ausometherapy.png")} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Ausome Therapy" />
			</Helmet>

			<div className="terms">
				<PostHero title="Terms and Use" />

				<div className="container">
					<div className="post-content-wrapper">
						<div className="post-aside">
							<div className="content">
								<div className="panel" id="Heading">
									<div className="panel-header">Heading</div>
									<div className="panel-body">
										<p>Please read these Terms of Use ("Terms", "Terms of Use") carefully before using the Ausome Therapy website (the "Service") operated by Ausome Therapy ("us", "we", or "our").</p>

										<p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the Terms, you may not access the Service.</p>
									</div>
								</div>
								<div className="panel" id="IntellectualProperty">
									<div className="panel-header">Intellectual Property</div>
									<div className="panel-body">
										<p>The Service and its original content, features, and functionality are owned by Ausome Therapy and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>

										<p>All images, icons, logos, and trademarks used on the Service are the property of their respective owners. These materials are used for informational and illustrative purposes only and do not imply endorsement or sponsorship.</p>
									</div>
								</div>
								<div className="panel" id="UseLicense">
									<div className="panel-header">Use License</div>
									<div className="panel-body">
										<p>Permission is granted to temporarily download one copy of the materials (information or software) on Ausome Therapy's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

										<ul>
											<li>Modify or copy the materials.</li>
											<li>Use the materials for any commercial purpose or for any public display (commercial or non-commercial).</li>
											<li>Attempt to decompile or reverse engineer any software contained on Ausome Therapy's website.</li>
											<li>Remove any copyright or other proprietary notations from the materials.</li>
											<li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
										</ul>

										<p>This license shall automatically terminate if you violate any of these restrictions and may be terminated by Ausome Therapy at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>
									</div>
								</div>
								<div className="panel" id="Disclaimer">
									<div className="panel-header">Disclaimer</div>
									<div className="panel-body">
										<p>The materials on Ausome Therapy's website are provided on an "as is" basis. Ausome Therapy makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>

										<p>Further, Ausome Therapy does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>
									</div>
								</div>
								<div className="panel" id="Limitations">
									<div className="panel-header">Limitations</div>
									<div className="panel-body">
										<p>In no event shall Ausome Therapy or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Ausome Therapy's website, even if Ausome Therapy or a Ausome Therapy authorized representative has been notified orally or in writing of the possibility of such damage.</p>
									</div>
								</div>
								<div className="panel" id="GoverningLaw">
									<div className="panel-header">Governing Law</div>
									<div className="panel-body">
										<p>These Terms shall be governed and construed in accordance with the laws of [Your Country], without regard to its conflict of law provisions.</p>
									</div>
								</div>
								<div className="panel" id="Changes">
									<div className="panel-header">Changes</div>
									<div className="panel-body">
										<p>Ausome Therapy reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>

										<p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.</p>
									</div>
								</div>
								<div className="panel" id="ContactUs">
									<div className="panel-header">Contact Us</div>
									<div className="panel-body">
										<p>If you have any questions about these Terms, please contact us at <a href="mailto:ausometherapy@gmail.com">ausometherapy@gmail.com</a></p>

										<small>Last update: April 02, 2024</small>
									</div>
								</div>
							</div>

							<aside className="aside hidden-sm">
								<div className="panel">
									<div className="panel-header">Table of Contents</div>
									<div className="panel-body">
										<p><a href="#Heading">Heading</a></p>
										<p><a href="#IntellectualProperty">Intellectual Property</a></p>
										<p><a href="#UseLicense">Use License</a></p>
										<p><a href="#Disclaimer">Disclaimer</a></p>
										<p><a href="#Limitations">Limitations</a></p>
										<p><a href="#GoverningLaw">Governing Law</a></p>
										<p><a href="#Changes">Changes</a></p>
										<p><a href="#ContactUs">Contact Us</a></p>
									</div>
								</div>
							</aside>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Terms;