import { NavLink, Outlet } from "react-router-dom";
import logo from "../assets/images/misc/logo.png";
import Footer from "../components/footer/Footer";
import { useLocation } from "react-router-dom";
import Nav from "../components/nav/Nav";

const Main = () => {
	const location = useLocation();
	const segments = location.pathname.split("/");

	return (
		<>
			<header><Nav /></header>

			<main><Outlet /></main>

			{
				segments.includes("search") ?
					<div
						style={
							{
								backgroundColor: "#fff",
								padding: "20px 0",
								textAlign: "center",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: 3,
								width: "100%"
							}
						}>
						<img src={logo} alt="" width={20} /><NavLink to="/">Ausome Therapy</NavLink><small>&copy; {new Date().getFullYear()}</small>
					</div>
					:
					<footer>
						<Footer />
					</footer>
			}

		</>
	)
}

export default Main;