import { searchCenterNameAPI, searchCenterLocationAPI } from "../../helpers/endpoints/search";
import { useSearchResult } from "../../helpers/states/useSearchResult";
import CentersHistory from "../../components/misc/CentersHistory";
import { useSearchParams, useNavigate } from "react-router-dom";
import CallToAction from "../../components/misc/CallToAction";
import CenterList from "./components/center-list/CenterList";
import Loading from "../../components/misc/Loading";
import SearchV2 from "./components/SearchV2";
import { Helmet } from "react-helmet-async";
import { FaArrowUp } from "react-icons/fa";
import { useEffect, useRef } from "react";
import axios from "axios";
import "./search.css";

const Index = () => {
	const { searchKeywords, searchResultData, setSearchResultData, searchType } = useSearchResult();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const urlQueryLocation = searchParams.get("location");
	const urlQueryCenter = searchParams.get("center");
	const targetElement = useRef(null);

	useEffect(() => {
		const searchCenters = async () => {
			let searchData = {};
			const allFilters = [
				"occupational_therapy",
				"speech_therapy",
				"playgroup",
				"special_education",
				"early_intervention",
				"applied_behavior_analysis",
				"physical_therapy"
			]

			if (urlQueryCenter) {
				searchData = {
					keywords: urlQueryCenter,
					endpoint: searchCenterNameAPI,
					filters: allFilters,
					type: "center",
				}
			}

			if (urlQueryLocation) {

				let filtersParam = "";

				if (searchParams.get("filters")) filtersParam = searchParams.get("filters").replace(" ", "").split(",");

				searchData = {
					keywords: urlQueryLocation,
					endpoint: searchCenterLocationAPI,
					filters: filtersParam || allFilters,
					type: "location",
				}
			}

			const response = await axios.get(searchData.endpoint, { params: { ...searchData } });

			const { data, keywords, filters, redirect } = response.data;

			setSearchResultData({
				searchKeywords: keywords,
				searchFilters: filters,
				searchResultData: data,
				searchType: searchData.type,
			});

			navigate(redirect);
		}

		if (!searchKeywords) {
			searchCenters();
		}

	}, [navigate, searchKeywords, searchParams, setSearchResultData, urlQueryCenter, urlQueryLocation]);


	const scrollToTop = () => {
		if (targetElement.current) targetElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	const capWords = (string) => {
		if (string) return string.split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
	}

	if (searchResultData === null) return <Loading />;

	return (
		<div className="search" ref={targetElement}>
			<Helmet>
				<title>{`${capWords(searchKeywords)}`} Occupational Therapy, Speech Therapy, ABA Therapy, Playgroup Program, and Early Intervention centers | Ausome Therapy</title>

				<meta itemprop="name" content="Ausome Therapy" />
				<meta name="description" content={`All occupational therapy, speech therapy, ABA therapy, playgroup program, and early intervention centers ${searchType === "location" ? `in ${capWords(searchKeywords)} area.` : `relevant to ${searchKeywords}.`}`} />

				<meta itemprop="description" content={`All occupational therapy, speech therapy, ABA therapy, playgroup program, and early intervention centers ${searchType === "location" ? `in ${capWords(searchKeywords)} area.` : `relevant to ${searchKeywords}.`}`} />
				<meta name="keywords" content={`${searchKeywords} therapy center, child development therapy center, occupational therapy, speech therapy, applied behavior analysis (aba) therapy, early intervention, playgroup programs`} />
				<meta itemprop="image" content="https://ausometherapy.com/ausometherapy.png" />

				<meta property="og:title" content={`${capWords(searchKeywords)} Occupational Therapy, Speech Therapy, ABA Therapy, Playgroup Program, and Early Intervention centers | Ausome Therapy`} />
				<meta property="og:description" content={`All occupational therapy, speech therapy, ABA therapy, playgroup program, and early intervention centers ${searchType === "location" ? `in ${capWords(searchKeywords)} area.` : `relevant to ${searchKeywords}.`}`} />
				<meta property="og:image" content="https://ausometherapy.com/ausometherapy.png" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
			</Helmet>

			<div className="container">
				<div className="result-page-search">
					<div className="result-page-header">
						{
							searchType === "location" ?
								<h1 itemProp="headline" style={{ fontSize: 16, marginBottom: 10 }}>
									<span className="font-normal">Therapy Centers near </span> <span itemProp="addressLocality" className="text-capitalize">{capWords(searchKeywords)}</span> <span className="font-normal">area</span>
								</h1>
								:
								<h1 itemProp="headline" style={{ fontSize: 16, marginBottom: 10 }}>
									<span className="font-normal">Results for </span> <span itemProp="addressLocality" className="text-capitalize">{searchKeywords}</span>
								</h1>
						}
					</div>

					<SearchV2 searchType={searchType} searchKeywords={capWords(searchKeywords)} />
				</div>

				<CenterList searchKeywords={searchKeywords} searchResultData={searchResultData} searchType={searchType} />

				{searchResultData.length > 20 && <div style={{ textAlign: "center", marginBottom: 20 }}><button className="visible-xs" style={{ borderRadius: 100, padding: "6px 13px" }} onClick={scrollToTop}><FaArrowUp /></button></div>}
			</div>

			<CentersHistory />

			<CallToAction />
		</div>
	)
}

export default Index;