import { useSearchResult } from "../../helpers/states/useSearchResult";
import CenterList from "../search/components/center-list/CenterList";
import CentersHistory from "../../components/misc/CentersHistory";
import CallToAction from "../../components/misc/CallToAction";
import SearchV2 from "../search/components/SearchV2";
import Loading from "../../components/misc/Loading";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaArrowUp } from "react-icons/fa";
import { useEffect, useRef } from "react";
import "../search/search.css";
import axios from "axios";
import { searchCenterLocationAPI } from "../../helpers/endpoints/search";

const Physical = () => {
	const { searchKeywords, searchResultData, setSearchResultData, searchType } = useSearchResult();
	const { city } = useParams();
	const targetElement = useRef(null);

	useEffect(() => {
		const searchQuery = {
			type: "location",
			filters: ["physical_therapy"]
		};

		const getSearchResult = async (city) => {
			const response = await axios.get(searchCenterLocationAPI, { params: { ...searchQuery, keywords: city } });

			const { data } = response.data;

			setSearchResultData({
				searchKeywords: city.replaceAll("+", " "),
				searchFilters: data.filters,
				searchResultData: data,
				searchType: "location"
			});
		};

		if (searchResultData === null) {
			getSearchResult(city);
		}

	}, [searchKeywords, city, searchResultData, setSearchResultData]);

	const scrollToTop = () => {
		if (targetElement.current) targetElement.current.scrollIntoView({ behavior: "smooth", block: "start" });
	}

	const capWords = (string) => {
		if (string) return string.replaceAll("+", " ").split(" ").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
	}

	if (searchResultData === null) return <Loading />;

	return (
		<div className="physical" ref={targetElement}>
			<Helmet>
				<title>{`Physical Therapy Centers near ${capWords(searchKeywords)} | Ausome Therapy`}</title>

				<meta name="description" content={`Ausome Therapy helps you find the best Physical Therapy Centers near ${capWords(searchKeywords)} area.`} />

				<meta itemprop="name" content="Ausome Therapy" />
				<meta itemprop="description" content={`Ausome Therapy helps you find the best Physical Therapy Centers near ${capWords(searchKeywords)} area.`} />
				<meta name="keywords" content={`SpEd Therapy, Physical Therapy Centers, Physical Therapy Centers near ${capWords(searchKeywords)}`} />
				<meta itemprop="image" content="https://ausometherapy.com/ausometherapy.png" />

				<meta property="og:title" content={`Physical Therapy Centers near ${capWords(searchKeywords)} | Ausome Therapy`} />
				<meta property="og:description" content={`Ausome Therapy helps you find the best Physical Therapy Centers near ${capWords(searchKeywords)} area.`} />
				<meta property="og:image" content="https://ausometherapy.com/ausometherapy.png" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Ausome Therapy" />

				<link rel="canonical" href={`https://ausometherapy.com/physical-therapy-centers/${searchKeywords.replaceAll(" ", "+")}`} />
			</Helmet>

			<div className="container">
				<div className="result-page-search">
					<div className="result-page-header">
						<h1 itemProp="headline" style={{ fontSize: 16, marginBottom: 10 }}>
							<span className="font-normal">Physical Therapy Centers near </span> <span itemProp="addressLocality" className="text-capitalize">{capWords(searchKeywords)}</span> <span className="font-normal">area</span>
						</h1>
					</div>

					<SearchV2 searchType={searchType} searchKeywords={capWords(searchKeywords)} />
				</div>

				<CenterList searchKeywords={searchKeywords} searchResultData={searchResultData} searchType={searchType} />

				{searchResultData.length > 20 && <div style={{ textAlign: "center", marginBottom: 20 }}><button className="visible-xs" style={{ borderRadius: 100, padding: "6px 13px" }} onClick={scrollToTop}><FaArrowUp /></button></div>}
			</div>

			<CentersHistory />

			<CallToAction />
		</div>
	)
}

export default Physical;