const PostHero = ({ title }) => {
	return (
		<div className="post-hero">
			<div className="container">
				<h1>{title}</h1>
			</div>
		</div>
	)
}

export default PostHero;