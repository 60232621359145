import { create } from "zustand";

export const useAlert = create((set, get) => (
	{
		active: false,
		status: "success",
		message: "Something went wrong. Please refresh the page.",
		setAlert: ({ active, status, message }) => set({
			active, status, message
		}),
	}
))