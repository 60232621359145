import { BiSolidQuoteAltRight } from "react-icons/bi";
import { testis } from "../../../assets/data/testis";

const Testi = () => {

	return (
		<section className="testi page-section">
			<div className="container">
				<div className="testi-header">
					<h2 style={{ fontSize: "2rem" }}>Ausome Therapy helps improve lives</h2>
					<p>Our hearts are full with all these testimonials <span className="color-theme">❤</span></p>
				</div>

				<div className="testi-list-wrapper">

					{
						testis.map((testi, index) =>
							<div key={index + testi.name} className="testi-item">
								<div className="testi-head">
									<BiSolidQuoteAltRight />
								</div>
								<div className="testi-body">
									<p className="nogaps">{testi.message}</p>
								</div>
								<div className="testi-footer">
									<img src={require(`../../../assets/images/misc/${testi.avatar}`)} alt="" />
									<div>
										<span className="testi-name">{testi.name}</span>
										<small>{testi.city}</small>
									</div>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</section>
	)
}

export default Testi;