import { Outlet } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Nav from "../components/nav/Nav";

const Post = () => {

	return (
		<>
			<header><Nav /></header>

			<main><Outlet /></main>

			<footer><Footer /></footer>
		</>
	)
}

export default Post;