/*
	PRODUCT TYPES:
	"applied_behavior_analysis"
	"occupational_therapy"
	"physical_therapy"
	"playgroup"
	"special_education"
	"speech_program"
	"speech_therapy"

	OBJECT TEMPLATE:
	{
		type: "xxx",
		image: "xxx",
		title: "xxx",
		link: "xxx",
		status: true,
	},
*/

export const shopeeProducts = [
	{
		type: "applied_behavior_analysis",
		image: "https://down-ph.img.susercontent.com/file/ph-11134211-7qukw-lkj9n0sgtuq45d_tn",
		title: "LED Light Fidget Spinner Light Fidget Finger Toy Hand Spinner Stress Reduction Children's Toys",
		link: "https://s.shopee.ph/4VHPkquUsI",
		status: true,
	},
	{
		type: "applied_behavior_analysis",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7qul7-lir2xqdzcc921e",
		title: "Sensory Toy for ASD Child Early Education and Learning Fine Motor Skills Autism",
		link: "https://s.shopee.ph/1fxEQ3fou5",
		status: true,
	},
	{
		type: "applied_behavior_analysis",
		image: "https://down-ph.img.susercontent.com/file/cb06eb566a47f3da05070a38a6ce38dd",
		title: "blithe baby soft cloth book educational infant bath toy teether toys sensory fruit shapes abc",
		link: "https://s.shopee.ph/3fiIo0iwDW",
		status: true,
	},
	{
		type: "applied_behavior_analysis",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7r98z-lpq6zz0f7x4hdf",
		title: "Baby Rainbow Stacking Toy Set Early Teaching Grasp Shape Ball Toy Sensory Improve Baby Sensory",
		link: "https://s.shopee.ph/2LCvDtk01Z",
		status: true,
	},
	{
		type: "applied_behavior_analysis",
		image: "https://down-ph.img.susercontent.com/file/sg-11134201-22110-hichlz4b4wjvd9",
		title: "Baby Sensory Toys Teething Pull String Toddler for Girl Boy Infant Newborn",
		link: "https://s.shopee.ph/LRqqNwNCg",
		status: true,
	},
	{
		type: "occupational_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7qul4-lhohz2byouzr29",
		title: "Shape Matching Egg Toys Toddlers Number Shape Early Learning Recognition Puzzle",
		link: "https://s.shopee.ph/2AtV1vpHVn",
		status: true,
	},
	{
		type: "occupational_therapy",
		image: "https://down-ph.img.susercontent.com/file/sg-11134201-7rbk0-lkushylxloa0d5",
		title: "25/40/60Pcs Kids String Beads Toy Rope Threading Toys Montessori Fine Motor Skill Children Concentration Early Education",
		link: "https://s.shopee.ph/4puGCsvW5s",
		status: true,
	},
	{
		type: "occupational_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-23010-6n11b02vrhmv51",
		title: " paper cutting activity scissor skill toddler fine motor skill toddler kids children",
		link: "https://s.shopee.ph/606DbPGuhB",
		status: true,
	},
	{
		type: "occupational_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7r98s-llu5tx1fgbkf1e",
		title: "Kids Montessori Educational Wooden Stick Men Puzzle Game Kids Hand Skill Fine Motor Training",
		link: "https://s.shopee.ph/8KU8Nl1wkR",
		status: true,
	},
	{
		type: "occupational_therapy",
		image: "https://down-ph.img.susercontent.com/file/sg-11134201-7qvfm-ljm8q87qmq66cb",
		title: "Busy Board Montessori Toys Baby Sensory For Learning Fine Motor Skills Early Education",
		link: "https://s.shopee.ph/4KxzcUO5ie",
		status: true,
	},
	{
		type: "speech_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7r992-ltujzfvq2ycwf3",
		title: "Problem Solution Flashcards Speech Therapy Cards thinkingtots.shop",
		link: "https://s.shopee.ph/5V9x0mtbzo",
		status: true,
	},
	{
		type: "speech_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7r98s-lufe8kuw67cic2",
		title: "Communication speech therapy laminated flashcards",
		link: "https://s.shopee.ph/1fxERnxn1w",
		status: true,
	},
	{
		type: "speech_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7r98t-llao71g4u3jm75",
		title: "Early Education Machine Pronunciation Intelligent Baby Children's Kids Bilingual Speech Therapy Toys",
		link: "https://s.shopee.ph/8AAiBoFpXG",
		status: true,
	},
	{
		type: "speech_therapy",
		image: "https://down-ph.img.susercontent.com/file/ph-11134207-7r98w-lsd8pvr9xidgbe",
		title: "Problem and Solving Interactive Book Speech Therapy and ABA Therapy material",
		link: "https://s.shopee.ph/9KMfa3Uxnx",
		status: true,
	},
	{
		type: "speech_therapy",
		image: "https://down-ph.img.susercontent.com/file/sg-11134201-22120-2x66uy9868kvd2",
		title: "Good & Bad Behaviour Flash Card for Kids Speech Therapy Speech Delay Autism ABA Tingkah laku Educational Cards",
		link: "https://s.shopee.ph/3q1j201kgG",
		status: true,
	},
	{
		type: "playgroup",
		image: "https://down-ph.img.susercontent.com/file/e01faa27e7449a2f8f4eca3dee2a70b4",
		title: "Cognitive Alphabet Spelling and Exercise Thinking Game",
		link: "https://s.shopee.ph/5pmn8epKty",
		status: true,
	},
]