import { useUrlHistory } from "../../helpers/states/useUrlHistory";
import logo from "../../assets/images/misc/logo.png";
import { useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./nav.css";

const Nav = () => {
	const location = useLocation();
	const segments = location.pathname.split("/");
	const { url, setUrlHistory } = useUrlHistory();

	return (
		<nav className="main-nav">
			<div className={`container${segments.includes("dash") ? "-fluid" : ""}`}>
				<div className="nav-grid">
					<div className="nav-left">

						{
							url ?
								<NavLink
									onClick={e => setUrlHistory({ url: false })}
									to={url}
									className="btn nav-back-btn">
									<FaArrowLeft style={{ color: "var(--color-theme)" }} /> <span>Back</span>
								</NavLink>
								:
								<div className="logo-wrapper">
									<NavLink to={"/"}><img src={logo} alt="logo" className="nav-logo" /><small className="app-name">Ausome Therapy</small></NavLink>
								</div>
						}
					</div>

					<div className="nav-center"></div>

					<div className="nav-right">
						<ul>
							<li><NavLink to={"/"} onClick={e => setUrlHistory({ url: false })}>Home</NavLink></li>
						</ul>
					</div>
				</div>
			</div>
		</nav>
	)
}

export default Nav;