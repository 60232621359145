import { create } from "zustand";

export const useRecentSelectedCenter = create(set => (
	{
		selectedCenters: [],
		setSelectedCenter: (center_id) => set((state) => {
			if (!state.selectedCenters.includes(center_id)) {
				let updatedValues;

				if (state.selectedCenters.length < 6) {
					updatedValues = [...state.selectedCenters, center_id];
				} else {
					updatedValues = [center_id, ...state.selectedCenters.slice(0, 5)];
				}

				return { selectedCenters: updatedValues };
			}

			return state;
		}),
	}
))