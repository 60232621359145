export const testis = [
	{
		name: "Donna",
		city: "Antipolo City",
		message: "Ausome Therapy saved us tons of hours looking for the right therapy center within our city. It exceeded our expectations. The app's user-friendly experience, along with updated details about therapy centers, makes Ausome Therapy a remarkable directory!",
		avatar: "donna.jpg",
	},
	{
		name: "Poi",
		city: "Malabon City",
		message: "I never thought this app existed. Stop wasting time on social media only to find fake therapy center pages. Ausome Therapy's user-friendly interface and comprehensive listings saved us time and energy. Highly recommended!",
		avatar: "poi.jpg",
	},
	{
		name: "Ema",
		city: "Bulacan",
		message: "Ausome Therapy made finding the right child developmental services for my sister stress-free. One of the best features is the Google Map, it's very helpful. A true blessing for families like ours!",
		avatar: "ema.jpg",
	},
	{
		name: "Ava",
		city: "Ilocos Norte",
		message: "Living in Ilocos Norte, it's challenging to find therapy centers. Ausome Therapy has simplified our research journey. From finding therapy centers to scheduling appointments, it's been a lifesaver!",
		avatar: "ava.jpg",
	},
];
