import { MdFacebook } from "react-icons/md";

const CallToAction = () => {
	return (
		<section className="cta page-section">
			<div className="container">
				<div className="cta-wrapper">
					<div>
						<h2>We're all in this together.</h2>
						<p>Let's share experiences, challenges, and small wins, one therapy session at a time.</p>
					</div>
					<a href="https://www.facebook.com/groups/ausometherapy" target="_new" className="btn btn-theme">
						<h3 style={{ margin: 0, padding: "8px 14px", display: "flex", alignItems: "center", lineHeight: 1.2 }}>JOIN AUSOME THERAPY COMMUNITY <MdFacebook className="icon-right" /></h3>
					</a>
				</div>
			</div>
		</section>
	)
}

export default CallToAction;