import { create } from "zustand";

export const useSession = create((set) => (
	{
		hasSession: false,
		userData: null,
		centers: null,
		setSession: ({ hasSession, userData, centers }) => set({
			hasSession, userData, centers
		})
	}
))