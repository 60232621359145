import PostHero from "../components/PostHero";
import { Helmet } from "react-helmet-async";
import "../posts.css";

const Privacy = () => {
	return (
		<>
			<Helmet>
				<title>Privacy Policy | Ausome Therapy</title>
				<meta name="description" content={`Thank you for choosing Ausome Therapy ("us", "we", or "our"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website ("Service").`} />

				<meta itemprop="name" content="Ausome Therapy" />
				<meta itemprop="description" content={`Thank you for choosing Ausome Therapy ("us", "we", or "our"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website ("Service").`} />
				<meta name="keywords" content="Ausome Therapy, Privacy Policy" />
				<meta itemprop="image" content={require("../../../assets/images/misc/ausometherapy.png")} />

				<meta property="og:title" content="Privacy Policy | Ausome Therapy" />
				<meta property="og:description" content={`Thank you for choosing Ausome Therapy ("us", "we", or "our"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website ("Service").`} />
				<meta property="og:image" content={require("../../../assets/images/misc/ausometherapy.png")} />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Ausome Therapy" />
			</Helmet>

			<div className="privacy">
				<PostHero title="Privacy Policy" />

				<div className="container">
					<div className="post-content-wrapper">
						<div className="post-aside">
							<div className="content">
								<div className="panel" id="Heading">
									<div className="panel-header">Heading</div>

									<div className="panel-body">
										<p>Thank you for choosing Ausome Therapy ("us", "we", or "our"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our website ("Service").</p>

										<p>We are committed to protecting your privacy and ensuring the security of your personal information. By using the Service, you agree to the collection and use of information in accordance with this policy.</p>
									</div>
								</div>

								<div className="panel" id="InformationCollectionandUse">
									<div className="panel-header">Information Collection and Use</div>

									<div className="panel-body">
										<p>We collect information for the purpose of providing and improving the Service. This may include:</p>

										<ul>
											<li>Personal Information: When you use our Service, we may collect personal information such as your name, email address, and contact information. This information is collected voluntarily when you submit it through forms on our website.</li>

											<li>Usage Data: We may also collect information about how you use the Service, such as your IP address, browser type, pages visited, and the time and date of your visit. This information is collected automatically through cookies and similar technologies.</li>
										</ul>
									</div>
								</div>

								<div className="panel" id="UseofInformation">
									<div className="panel-header">Use of Information</div>

									<div className="panel-body">
										<p>We use the information we collect for various purposes, including:</p>

										<ul>
											<li>Providing and maintaining the Service.</li>
											<li>Personalizing your experience and improving the Service.</li>
											<li>Communicating with you, including responding to inquiries and providing updates about the Service.</li>
											<li>Analyzing usage trends and gathering demographic information.</li>
											<li>Protecting the security and integrity of the Service.</li>
										</ul>
									</div>
								</div>

								<div className="panel" id="DisclosureofInformation">
									<div className="panel-header">Disclosure of Information</div>

									<div className="panel-body">
										<p>We may disclose your personal information in the following circumstances:</p>

										<ul>
											<li>With your consent.</li>
											<li>To comply with legal obligations.</li>
											<li>To protect and defend our rights or property.</li>
											<li>In connection with a business transaction, such as a merger or acquisition.</li>
										</ul>
									</div>
								</div>
								<div className="panel" id="ThirdPartyServices">
									<div className="panel-header">Third-Party Services</div>

									<div className="panel-body">
										<p>We may use third-party services to facilitate our Service or to provide additional features. These third parties have access to your personal information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
									</div>
								</div>
								<div className="panel" id="Data Security">
									<div className="panel-header">Data Security</div>

									<div className="panel-body">
										<p>We take the security of your personal information seriously and employ appropriate measures to protect it against unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
									</div>
								</div>
								<div className="panel" id="LinkstoOtherSites">
									<div className="panel-header">Links to Other Sites</div>

									<div className="panel-body">
										<p>Our Service may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that site. We strongly advise you to review the privacy policy of every site you visit.</p>
									</div>
								</div>
								<div className="panel" id="ChildrensPrivacy">
									<div className="panel-header">Children's Privacy</div>

									<div className="panel-body">
										<p>Our Service does not address anyone under the age of 18 ("Children"). We do not knowingly collect personal information from children. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we can take appropriate action.</p>
									</div>
								</div>
								<div className="panel" id="ChangestoThisPrivacyPolicy">
									<div className="panel-header">Changes to This Privacy Policy</div>

									<div className="panel-body">
										<p>We may update our Privacy Policy from time to time. Any changes will be posted on this page, with the "Last updated" date indicating the effective date of the changes. We encourage you to review this Privacy Policy periodically for any updates.</p>
									</div>
								</div>
								<div className="panel" id="ContactUs">
									<div className="panel-header">Contact Us</div>

									<div className="panel-body">
										<p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:ausometherapy@gmail.com">ausometherapy@gmail.com</a></p>

										<small>Last update: April 02, 2024</small>
									</div>
								</div>
							</div>

							<aside className="aside hidden-sm">
								<div className="panel">
									<div className="panel-header">Table of Contents</div>
									<div className="panel-body">
										<p><a href="#Heading">Heading</a></p>
										<p><a href="#InformationCollectionandUse">Information Collection and Use</a></p>
										<p><a href="#UseofInformation">Use of Information</a></p>
										<p><a href="#DisclosureofInformation">Disclosure of Information</a></p>
										<p><a href="#ThirdPartyServices">Third-Party Services</a></p>
										<p><a href="#DataSecurity">Data Security</a></p>
										<p><a href="#LinkstoOtherSites">Links to Other Sites</a></p>
										<p><a href="#ChildrensPrivacy">Children's Privacy</a></p>
										<p><a href="#ChangestoThisPrivacyPolicy">Changes to This Privacy Policy</a></p>
										<p><a href="#ContactUs">Contact Us</a></p>
									</div>
								</div>
							</aside>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Privacy;