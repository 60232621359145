import notFound from "../../assets/images/misc/not-found.png";
import { NavLink } from "react-router-dom";

const Index = () => {
	return (
		<div className="error-page">
			<div className="container">
				<div style={{ textAlign: "center", maxWidth: 600, margin: "58px auto" }}>
					<img src={notFound} alt="" style={{ width: "100%" }} />
					<div>
						<h4 style={{ marginBottom: 20 }}>Oopsie, we got lost in the woods!</h4>
						<NavLink className="btn btn-lg btn-theme" to={"/"}>Lets go back Home</NavLink>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index;