import { create } from "zustand";

export const useSearchResult = create((set, get) => (
	{
		searchKeywords: "",
		searchFilters: [],
		searchResultData: null,
		searchType: "location",
		setSearchResultData: ({ searchKeywords, searchFilters, searchResultData, searchType }) => set(
			{ searchKeywords, searchFilters, searchResultData, searchType }
		),
	}
))