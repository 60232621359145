const Featured = () => {
	return (
		<section className="featured page-section" itemScope itemType="http://schema.org/LocalBusiness">
			<div className="container">
				<div className="featured-grid">
					<div className="featured-header" itemProp="name">
						<h1 style={{ fontSize: "2.6rem" }} itemProp="headline">Therapy Center Directory</h1>
						<p itemProp="description">Discover top-rated child developmental therapy centers in the Philippines all in one place. Whether you're seeking Occupational Therapy (OT), Speech Therapy (ST), ABA, Playgroup, or Developmental Pediatricians, Ausome Therapy makes it easy to find the care you need for your ausome child.</p>

					</div>
					<div className="featured-bg"></div>

					<p className="visible-sm" style={{ textAlign: "left" }}>Ausome Therapy collected over 100 child developmental therapy centers nationwide, ensuring that families across the Philippines have access to comprehensive care and support for their children's developmental needs. With our extensive network of trusted centers, finding the right resources and expertise has never been easier.</p>
				</div>

			</div>
		</section>
	)
}

export default Featured;