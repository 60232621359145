import axios from "axios";
import switchAPI from "./switchAPI";

axios.defaults.withCredentials = true;

export const allCentersAPI = `${switchAPI}/centers/get-all-centers`;

export const getSingleCenter = `${switchAPI}/centers/get-single-center`;

export const getCentersById = `${switchAPI}/centers//get-centers-id`;

// see CenterCounter component at pages/landing
export const countersAPI = `${switchAPI}/centers/get-counters`;